import { defineStore } from 'pinia'
import restApi from '@/service/restApi';
import router from '@/router';
import { has } from 'lodash';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
  }),

  getters: {
    loggedIn: (state) => has(state.user, 'id'),
  },

  actions: {
    async fetch() {
      const response = await restApi.get('session');

      if (response.status === 200) {
        this.user = await response.json();
      }
      else if (response.statue === 204) {
        this.$reset();
      }
    },

    async logout() {
      const logout = await restApi.delete('session');
      this.$reset();
      router.push({ name: 'login' });
    },
  }
})
