<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';

const Layouts = {
  LoginLayout:   defineAsyncComponent(() => import('@/layouts/LoginLayout.vue')),
  UploadLayout:  defineAsyncComponent(() => import('@/layouts/UploadLayout.vue')),
  DefaultLayout: defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue')),
  LoadingLayout: defineAsyncComponent(() => import('@/layouts/LoadingLayout.vue')),
}

const route  = useRoute();
const layout = computed(() => Layouts[route.meta.layout || 'LoadingLayout']);

</script>
