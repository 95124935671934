import { createRouter, createWebHistory } from 'vue-router';

import { cloneDeep, forEach, isEmpty, toString } from 'lodash';

// Any paths we define here must have matching location entries in nginx.
// See: ui/image/etc/nginx/conf.d/default.conf
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      persistQuery: true,
    },
  },
  {
    path: '/presentation/:presentationId',
    name: 'presentation',
    component: () => import('@/views/Presentation.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'LoginLayout',
      noAuthRequired: true,
    },
  },
  {
    path: '/presentations',
    name: 'presentations',
    component: () => import('@/views/Presentations.vue'),
    meta: {
      persistQuery: true,
    },
  },
  {
    path: '/uploads',
    name: 'uploads',
    component: () => import('@/views/Uploads.vue'),
    meta: {
      persistQuery: true,
    },
  },
  {
    path: '/upload/:uploadRequestId',
    name: 'upload',
    component: () => import('@/views/Upload.vue'),
    meta: {
      layout: 'UploadLayout',
      noAuthRequired: true,
    },
  },
];

// So that we can detect the difference between 'no layout specified' and 'no
// route yet', make sure all the routes have a layout.
for (const route of routes) {
  route.meta        ||= { };
  route.meta.layout ||= 'DefaultLayout';
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

import { useUserStore } from '@/store/user';

router.beforeEach(async (to, from) => {
  let ret = persistQuery(to, from);
  const user = useUserStore();

  if (to.meta.noAuthRequired || user.loggedIn) {
    return ret;
  }

  await user.fetch();
  if (user.loggedIn) {
    return ret;
  }

  return { name: 'login' };
});

// We store persisted queries here, keyed on the route name.
const persistedQuery = { };

function persistQuery(to, from) {
  // If staying in the same page, don't mess with the query string.
  if (from.name == to.name) {
    return true;
  }

  // If the page we are leaving wants persistent queries, save it now.
  if (from.meta.persistQuery) {
    persistedQuery[from.name] = from.query;
  }

  // If the page we are entering wants persistent queries, *and* it doesn't
  // have a query string of its own, restore it.
  if (to.meta.persistQuery && isEmpty(to.query)) {
    const query = persistedQuery[to.name];
    if (!isEmpty(query)) {
      return { name: to.name, query: persistedQuery[to.name] };
    }
  }

  return true;
}

router.updateQueryParams = (newParams) => {
  const route = router.currentRoute.value;
  const query = cloneDeep(route.query);
  let updates = [];
  forEach(newParams, (value, key) => {
    const oldValue = toString(query[key]);
    const newValue = toString(value);
    if (newValue != oldValue) {
      if (newValue.length > 0) {
        query[key] = value;
      }
      else {
        delete query[key];
      }
      updates.push(`${key}=${newValue}`);
    }
  });
  if (updates.length > 0) {
    router.push({ name: route.name, query: query });
  }
}

export default router;
