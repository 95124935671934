import { createApp } from 'vue'

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-042686 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Logicly Pty Ltd )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 28 July 2024 )____[v2]_MTcyMjEyMTIwMDAwMA==3d8c92c0fd782c402eaaf672fdf8afb3');

import App from './App.vue'

import './assets/tailwind.css'
const app = createApp(App);

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import Router from './router.js';
app.use(Router);

app.mount('#app');
